import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import logo_fr from "../../assets/exppro-logo-600px-francais-white.svg";
import logo_en from "../../assets/exppro-logo-600px-anglais-white.svg";
import logo_es from "../../assets/exppro-logo-600px-spanish-white.svg";
import logo_fr_black from "../../assets/exppro-logo-600px-francais.svg";
import logo_en_black from "../../assets/exppro-logo-600px-anglais.svg";
import logo_es_black from "../../assets/exppro-logo-600px-spanish.svg";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { colors } from "../../theme/colors";
import { Form, Formik } from "formik";
import { useAuthContext } from "../../hooks/useAuthContext";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useLogin } from "../../hooks/useLogin";
import CustomLanguageSelector from "../../components/ui/CustomLanguageSelector";
import CustomLangSelectorMobile from "../../components/ui/CustomLangSelectorMobile";
import { Delete, Logout } from "@mui/icons-material";

const NavbarEmployee = ({}) => {
  const { user } = useAuthContext();
  const [path, setPath] = useState();
  const [update, setUpdate] = useState(false);
  const { refreshToken } = useLogin();
  const location = useLocation();
  const navigate = useNavigate();
  const locations_fr = [
    { path: "/employee/gestion-des-traiter/liste", name: "À Traiter" },
    { path: "/employee/gestion-des-rappels/liste", name: "Rappel" },
    { path: "/employee/gestion-des-suivis/liste", name: "Suivi" },
    { path: "/employee/gestion-des-produits/liste", name: "Produits" },
  ];
  const location_en = [
    { path: "/employee/gestion-des-traiter/liste", name: "To Process" },
    { path: "/employee/gestion-des-rappels/liste", name: "Recall" },
    { path: "/employee/gestion-des-suivis/liste", name: "Actions" },
    { path: "/employee/gestion-des-produits/liste", name: "Products" },
  ];
  const location_es = [
    { path: "/employee/gestion-des-traiter/liste", name: "A Procesar" },
    { path: "/employee/gestion-des-rappels/liste", name: "Retiro" },
    { path: "/employee/gestion-des-suivis/liste", name: "Acciones" },
    { path: "/employee/gestion-des-produits/liste", name: "Productos" },
  ];
  const lang = localStorage.getItem("i18nextLng");
  const locations = lang === "fr" ? locations_fr : lang === "es" ? location_es : lang === "en" ? location_en : [];
  const { id } = useParams();
  const [upc, setUpc] = useState(user?.authenticatedUser?.role === 1 ? (location?.pathname.includes("/admin/gestion-des-produits/liste/search/") ? id : "") : user?.authenticatedUser.role === 4 ? (location?.pathname.includes("/lanceur-alerte/gestion-des-produits/liste/search/") ? id : "") : user?.authenticatedUser?.role === 2 ? (location?.pathname.includes("/lanceur-alerte/gestion-des-produits/liste/search/") ? id : "") : user?.authenticatedUser?.role === 3 ? (location?.pathname.includes("/employee/gestion-des-produits/liste/search/") ? id : "") : "");
  const { t } = useTranslation();

  const handleSubmit = () => {
    if (user?.authenticatedUser?.role === 1 && upc !== "") {
      if (location.pathname.includes("/admin/gestion-des-produits/liste/search/")) {
        navigate(`/admin/gestion-des-produits/liste/search/${upc}`);
        document?.location?.reload();
      } else {
        navigate(`/admin/gestion-des-produits/liste/search/${upc}`);
      }
    }
    if (user?.authenticatedUser?.role === 4 && upc !== "") {
      if (location.pathname.includes("/lanceur-alerte/gestion-des-produits/liste/search/")) {
        navigate(`/lanceur-alerte/gestion-des-produits/liste/search/${upc}`);
        document?.location?.reload();
      } else {
        navigate(`/lanceur-alerte/gestion-des-produits/liste/search/${upc}`);
      }
    }
    if (user?.authenticatedUser?.role === 2 && upc !== "") {
      if (location.pathname.includes("/marchand/gestion-des-produit/liste/search/")) {
        navigate(`/marchand/gestion-des-produit/liste/search/${upc}`);
        document?.location?.reload();
      } else {
        navigate(`/marchand/gestion-des-produit/liste/search/${upc}`);
      }
    }
    if (user?.authenticatedUser?.role === 3 && upc !== "") {
      if (location.pathname.includes("/employee/gestion-des-produits/liste/search/")) {
        navigate(`/employee/gestion-des-produits/liste/search/${upc}`);
        document?.location?.reload();
      } else {
        navigate(`/employee/gestion-des-produits/liste/search/${upc}`);
      }
    }
  };
  useEffect(() => {
    if (update === "updated") {
      handleLogout();
    }
  }, [update]);
  const handleLogout = () => {
    Api.post(
      Urls.BLACK_LIST_REFRESH_TOKEN,
      {
        refresh: user?.refresh,
      },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${user?.access}` },
      }
    )
      .then((res) => {
        localStorage.removeItem("user");
        window.location.href = "/";
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(user?.refresh).then((res) => {
            setUpdate("updated");
          });
        } else {
          localStorage.removeItem("user");
          window.location.href = "/";
        }
      });
  };
  return (
    <Box sx={{ maxWidth: "100%" }}>
      <Box
        sx={{
          boxShadow: "0",
          backgroundColor: `white`,
          boxSizing: "border-box",
          padding: "0",
          minHeight: "80px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: { xs: "370px", sm: "520px", md: "520px", lg: "520px" }, padding: "0", margin: "0", color: "white", display: "flex", alignItems: "center", flexDirection: "column" }}>
            <Box sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" }, justifyContent: "space-between", width: "100%", alignItems: "center" }} display="flex">
              <Box justifyContent="space-between" display="flex" alignItems="center" position="relative" sx={{ background: "white", width: "100%" }}>
                <Box display="flex" alignItems="cente" margin="0px 40px 0px 10px">
                  {t("logo") === "logo_fr" && <img src={logo_fr_black} style={{ marginLeft: "10px", aspectRatio: "2/1", height: "50px", width: "auto" }} alt="expo" />}
                  {t("logo") === "logo_en" && <img src={logo_en_black} style={{ marginLeft: "10px", aspectRatio: "2/1", height: "50px", width: "auto" }} alt="expo" />}
                  {t("logo") === "logo_es" && <img src={logo_es_black} style={{ marginLeft: "10px", aspectRatio: "2/1", height: "50px", width: "auto" }} alt="expo" />}
                </Box>
                <Box margin="0px 10px" sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  <CustomLangSelectorMobile />
                  <IconButton onClick={handleLogout}>
                    <Logout sx={{ color: colors?.main }} />
                  </IconButton>
                </Box>
              </Box>
            </Box>
            <Box sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" }, justifyContent: "space-between", width: "100%", alignItems: "center" }} display="flex">
              <Box flexDirection="column" flex={1} width="100%" display="flex" marginBottom="10px" alignItems="center" justifyContent="center">
                <Box display="flex" flexDirection="column" alignItems="center" backgroundColor={colors?.gradient90} borderRadius="8px">
                  <Box display="flex" alignItems="center" justifyContent="center" sx={{ background: colors?.gradient90, padding: "1px 5px", borderRadius: "8px" }}>
                    <Formik
                      initialValues={{
                        search: "",
                      }}
                      onSubmit={handleSubmit}
                    >
                      {({ setFieldValue, values }) => (
                        <Form>
                          <Box sx={{ width: { xs: "350px", sm: "500px", md: "500px", lg: "500px" } }} display="flex" alignItems="center" padding="5px 5px" justifyContent="center">
                            <IconButton
                              onClick={() => {
                                setUpc("");
                              }}
                            >
                              <Delete sx={{ color: "white", fontSize: 30 }} />
                            </IconButton>
                            <Box>
                              <input
                                style={{ height: "56px", width: "90%", borderRadius: "8px", fontSize: "18px", padding: "3px 5px" }}
                                onChange={(e) => {
                                  setUpc(e.target.value);
                                  setFieldValue("search", e.target?.value);
                                }}
                                value={upc}
                                placeholder={t("search_product")}
                              />
                            </Box>
                            <IconButton type="submit" sx={{ borderRadius: "0" }}>
                              <SearchIcon sx={{ color: "white", fontSize: 30 }} />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setUpc("");
                              }}
                            >
                              <Delete sx={{ color: "white", fontSize: 30 }} />
                            </IconButton>
                          </Box>
                        </Form>
                      )}
                    </Formik>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center", width: { xs: "370px", sm: "520px", md: "520px", lg: "520px" }, justifyContent: "center", justifyContent: "space-between" }}>
          {locations?.map((loc) => (
            <Box>
              {" "}
              <Button
                sx={{ display: { xs: "flex", sm: "none", md: "none", lg: "none" }, fontSize: "14px", padding: lang === "es" ? "3px 10px" : "3px 16px !important" }}
                onClick={() => {
                  navigate(loc?.path);
                }}
                variant={location.pathname.includes(loc.path) ? "primary_selected" : "primary_not_selected"}
              >
                {loc.name}
              </Button>
              <Button
                sx={{ display: { xs: "none", sm: "flex", md: "flex", lg: "flex" }, fontSize: "18px", padding: "3px 25px !important" }}
                onClick={() => {
                  navigate(loc?.path);
                }}
                variant={location.pathname.includes(loc.path) ? "primary_selected" : "primary_not_selected"}
              >
                {loc.name}
              </Button>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
export default NavbarEmployee;
