import { Alert, Autocomplete, Box, Button, Checkbox, FormControlLabel, IconButton, Modal, TextField, Typography } from "@mui/material";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useEffect, useState } from "react";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { useLogin } from "../../../hooks/useLogin";
import { toast, ToastContainer } from "react-toastify";
import imagePlaceholder from "../../../assets/placeholder-4-300x200.png";
import { useTranslation } from "react-i18next";
import { colors } from "../../../theme/colors";
import moment from "moment";
import Loading from "../../../components/ui/Loading";
import { ArrowLeft, ArrowRight, CloseOutlined, Delete } from "@mui/icons-material";
import { action_types_en, action_types_es, action_types_fr, action_types_list_en, action_types_list_es, action_types_list_fr } from "../../../assets/data/ActionTypes";

import { useParams } from "react-router-dom";
import * as yup from "yup";
import { modalStyle } from "../../../theme/ModalStyle";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ErrorMessage, Field, Form, Formik } from "formik";
import FormikErrorText from "../../../components/ui/FormikErrorText";
import { categories_list_en, categories_list_es, categories_list_fr } from "../../../assets/data/categories";
const ProductDetailsEMP = ({ showField }) => {
  const { user } = useAuthContext();
  const access_token = user?.access;
  const refresh_token = user?.refresh;
  const [prod, setProd] = useState([]);
  const { refreshToken } = useLogin();
  const { t } = useTranslation();
  const { id } = useParams();
  const [changeDate, setChangeDate] = useState({ state: false, data: null });
  const [changeDateNoDateA, setChangeDateNoDate] = useState({ state: false, data: null });
  const [changeDateIN, setChangeDateIN] = useState({ state: false, data: null });
  const [changeDateNoDateIN, setChangeDateNoDateIN] = useState({ state: false, data: null });
  const lang = localStorage.getItem("i18nextLng");

  const [loading, setLoading] = useState(true);
  const [modification, setModification] = useState(false);
  const [openGestionInvendu, setOpenGestionInvendu] = useState(false);
  const [openGestionDate, setOpenGestionDate] = useState(false);
  const [noDispo, setNoDispo] = useState(false);
  const [date_unavaillable, setDateUn] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [isSubmitingChangeDate, setIsSubmitingChangeDate] = useState(false);
  let action_types_list = lang === "fr" ? action_types_list_fr : lang === "en" ? action_types_list_en : lang === "es" ? action_types_list_es : {};
  const categories = lang === "fr" ? categories_list_fr : lang === "en" ? categories_list_en : categories_list_es;
  const [initialValuesGestionDate, setinitialValuesGestionDate] = useState({
    expiration_date: null,
    empty_shelf: false,
    category_value: categories[0]?.value,
    category: categories[0],
    date_non_dispo: false,
    location: "",
  });
  const [initialValuesGestionInvendu, setinitialValuesGestionInvendu] = useState({
    expiration_date: null,
    location: "",
    empty_shelf: false,
    date_non_dispo: false,
    prix: "",
    category_value: categories[0]?.value,
    category: categories[0],
    prix_red: "",
    action: { name: "", des: "" },
    quantity: "",
  });
  const validationSchema = yup.object({
    expiration_date: yup.date().required(t("validation_required")),
    category_value: yup?.string().nonNullable(t("validation_required")).required(t("validation_required")),
  });
  const validationSchemaInvendu = yup.object({
    expiration_date: yup.date().required(t("validation_required")),
    category_value: yup?.string().nonNullable(t("validation_required")).required(t("validation_required")),

    action: yup
      .object({
        des: yup.string().required(t("validation_required")),
      })
      .required(t("validation_required")),

    quantity: yup.number().min(0).required(t("validation_required")),
  });
  const validationSchemaNoDate = yup.object({
    category_value: yup?.string().nonNullable(t("validation_required")).required(t("validation_required")),
    action: yup
      .object({
        des: yup.string().required(t("validation_required")),
      })
      .required(t("validation_required")),
    quantity: yup.number().min(0).required(t("validation_required")),
  });
  useEffect(() => {
    setLoading(true);
    Api.get(Urls.GET_PRODUITS_EMPLOYEE, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        offset: 0,
        limit: 25,
        upc: id,
      },
    })
      .then((res) => {
        setProd(res?.data?.results[0] ? res?.data?.results[0] : null);
        setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            setModification(!modification);
          });
        } else {
          toast.error(err?.response?.data?.details);
          setLoading(false);
        }
      });
  }, [modification]);
  const ChangeDate = (values) => {
    setIsSubmitingChangeDate(true);

    Api.post(
      Urls.ChangeDate(prod.id),
      {
        expiration_date: values?.expiration_date,
        ...(values?.location && { location: values.location }),
        empty_shelf: empty,
        category: values.category_value,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        setOpenGestionDate(false);
        setModification(!modification);
        setIsSubmitingChangeDate(false);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            setChangeDate({ state: !changeDate, data: values });
          });
        } else {
          toast.error(err?.response?.data?.details);
          setIsSubmitingChangeDate(false);
        }
      });
  };
  const ChangeDateNoDate = (values) => {
    setIsSubmitingChangeDate(true);

    Api.post(
      Urls.ChangeDateNoDate(prod.id),
      {
        ...(values?.location && { location: values.location }),
        empty_shelf: empty,
        category: values.category_value,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        setOpenGestionDate(false);
        setModification(!modification);
        setIsSubmitingChangeDate(false);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            setChangeDateNoDate({ state: !changeDateNoDateA, data: values });
          });
        } else {
          toast.error(err?.response?.data?.details);
          setIsSubmitingChangeDate(false);
        }
      });
  };
  useEffect(() => {
    if (changeDate.data) ChangeDate(changeDate?.data);
  }, [changeDate]);
  useEffect(() => {
    if (changeDateNoDateA?.data) ChangeDateNoDate(changeDateNoDateA.data);
  }, [changeDateNoDateA]);
  const InvenduWithDate = (values) => {
    setIsSubmitingChangeDate(true);

    Api.post(
      Urls.TreatUnsoldWithDate(prod.id),
      {
        expiration_date: values?.expiration_date,
        ...(values?.location && { location: values.location }),
        action: values.action.des,
        category: values.category_value,
        quantity: values?.quantity,
        empty_shelf: empty,
        ...(values?.prix && { price: values?.prix }),
        ...(values?.prix_red && { price_reduction: values.prix_red }),
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        setOpenGestionInvendu(false);
        setModification(!modification);
        setIsSubmitingChangeDate(false);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            setChangeDateIN({ state: !changeDate, data: values });
          });
        } else {
          toast.error(err?.response?.data?.details);
          setIsSubmitingChangeDate(false);
        }
      });
  };
  const InvenduWithoutDate = (values) => {
    setIsSubmitingChangeDate(true);
    Api.post(
      Urls.TreatUnsoldWithNoDate(prod.id),
      {
        action: values.action.des,
        quantity: values?.quantity,
        empty_shelf: empty,
        category: values.category_value,
        ...(values?.location && { location: values.location }),
        ...(values?.prix && { price: values?.prix }),
        ...(values?.prix_red && { price_reduction: values.prix_red }),
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        setOpenGestionInvendu(false);
        setModification(!modification);
        setIsSubmitingChangeDate(false);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            setChangeDateNoDateIN({ state: !changeDateNoDateIN, data: values });
          });
        } else {
          toast.error(err?.response?.data?.details);
          setIsSubmitingChangeDate(false);
        }
      });
  };
  useEffect(() => {
    if (changeDateIN.data) InvenduWithDate(changeDateIN?.data);
  }, [changeDateIN]);
  useEffect(() => {
    if (changeDateNoDateIN.data) InvenduWithoutDate(changeDateNoDateIN.data);
  }, [changeDateNoDateIN]);
  const calculateDaysDiff = (date2) => {
    const date1obj = new Date();
    const date2obj = new Date(date2);
    const timeDiff = date2obj - date1obj;
    const dayDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    return dayDiff;
  };
  return (
    <Box>
      <ToastContainer />
      {loading && <Loading />}
      {prod === null && (
        <Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
            <Alert severity="warning" sx={{ textAlign: "center" }}>
              {t("changement_cat")}
            </Alert>
          </Box>
        </Box>
      )}
      {!loading && prod !== null && (
        <Box marginBottom="24px" display="flex" flexDirection="column" gap="24px">
          <Box display="flex" alignItems="center" justifyContent="center" gap="16px">
            <Button
              onClick={() => {
                setinitialValuesGestionInvendu({ ...initialValuesGestionInvendu, category_value: prod?.category ? prod?.category : initialValuesGestionInvendu?.category_value, category: categories.filter((cat) => cat?.value === prod?.category)[0] ? categories.filter((cat) => cat?.value === prod?.category)[0] : initialValuesGestionDate?.category, date_non_dispo: prod?.expiration_date_not_available, location: prod?.location, expiration_date: prod?.expiration_date, prix: prod?.price, prix_red: prod?.price_reduction });
                setEmpty(prod?.empty_shelf);
                setNoDispo(prod?.empty_shelf || prod?.expiration_date_not_available);
                setDateUn(prod?.expiration_date_not_available);
                setOpenGestionInvendu(true);
              }}
              variant="primary"
            >
              {t("invendu")}
            </Button>
            <Button
              onClick={() => {
                setEmpty(prod?.empty_shelf);
                setNoDispo(prod?.empty_shelf || prod?.expiration_date_not_available);
                setDateUn(prod?.expiration_date_not_available);
                setinitialValuesGestionDate({
                  expiration_date: prod?.expiration_date,
                  category_value: prod?.category ? prod?.category : initialValuesGestionDate?.category_value,
                  category: categories.filter((cat) => cat?.value === prod?.category)[0] ? categories.filter((cat) => cat?.value === prod?.category)[0] : initialValuesGestionDate?.category,
                  location: prod?.location,
                });

                setOpenGestionDate(true);
              }}
              variant="primary"
            >
              {t("modify_date")}
            </Button>
          </Box>
          <Box sx={{ cursor: "pointer", flexDirection: "column" }} display="flex" gap="20px" alignItems="center" backgroundColor="rgba(211,211,211 , 0.5)" borderRadius="16px" padding="10px">
            <Box sx={{ flex: "1" }}>
              <img style={{ height: "auto", width: "auto", aspectRatio: "1/1" }} src={prod?.use_admin_image ? (prod.product_image?.product_image ? process.env.REACT_APP_IMAGE_URL + prod.product_image?.product_image : imagePlaceholder) : prod.private_product_image ? process.env.REACT_APP_IMAGE_URL + prod.private_product_image : imagePlaceholder} alt="produit" />
            </Box>
            <Box flex={1} sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Typography sx={{ display: "inline", fontSize: "15px", fontWeight: "600", color: "black" }}>
                UPC: <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.upc}</Typography>
              </Typography>
              <Typography sx={{ display: "inline", fontSize: "15px", fontWeight: "600", color: "black" }}>
                <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.designation}</Typography>
              </Typography>
              <Typography sx={{ display: "inline", fontSize: "15px", fontWeight: "600", color: "black" }}>
                {t("forms.labels.format")}: <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.format}</Typography>
              </Typography>
              <Box>
                <Typography sx={{ fontSize: "15px", fontWeight: "600", color: "black" }}>{t("forms.labels.expiration_date")}:</Typography>
                <Typography sx={{ fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.expiration_date ? moment(prod?.expiration_date).format("MMMM DD, yyyy") : "-"}</Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize: "15px", fontWeight: "600", color: "black" }}>{t("expire_d")}:</Typography>
                <Typography sx={{ fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.expiration_date && calculateDaysDiff(prod?.expiration_date) + " " + t("days")} </Typography>
              </Box>
              {showField && (
                <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                  <Typography sx={{ display: "inline", fontSize: "15px", fontWeight: "600", color: "black" }}>
                    {t("forms.labels.created_at")}: <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{moment(prod?.created_at).format("MMMM DD, yyyy")}</Typography>
                  </Typography>
                  <Typography sx={{ display: "inline", fontSize: "15px", fontWeight: "600", color: "black" }}>
                    {t("forms.labels.created_by")}: <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.created_by}</Typography>
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
      <Modal open={openGestionDate} onClose={() => setOpenGestionDate(false)}>
        <Box sx={modalStyle}>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <IconButton onClick={() => setOpenGestionDate(false)}>
              <CloseOutlined />
            </IconButton>
          </Box>
          <Formik
            initialValues={initialValuesGestionDate}
            validationSchema={!noDispo ? validationSchema : null}
            enableReinitialize
            onSubmit={(values) => {
              if (noDispo) ChangeDateNoDate(values);
              else ChangeDate(values);
            }}
          >
            {({ errors, values, setFieldValue }) => (
              <Form>
                <Box display="flex" flexDirection="column" gap="24px">
                  <Box textAlign="center" display="flex" flexDirection="column" gap="10px">
                    <Typography sx={{ fontSize: "15px", fontWeight: "600", color: "black" }}>{t("forms.labels.expiration_date")}</Typography>{" "}
                    <Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton onClick={() => setFieldValue("expiration_date", null)}>
                          <Delete sx={{ color: colors?.main }} />
                        </IconButton>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            inputFormat="yyyy-MM-dd"
                            value={values.expiration_date}
                            disabled={noDispo}
                            minDate={new Date()}
                            InputProps={{ sx: { "& .MuiSvgIcon-root": { color: colors?.main, height: "50px", width: "50px" } } }}
                            onChange={(value) => {
                              setFieldValue("expiration_date", moment(value).format("YYYY-MM-DD"));
                            }}
                            renderInput={(params) => <TextField sx={{ maxWidth: "250px" }} {...params} />}
                          />
                        </LocalizationProvider>
                        <IconButton onClick={() => setFieldValue("expiration_date", null)}>
                          <Delete sx={{ color: colors?.main }} />
                        </IconButton>
                      </Box>
                      <ErrorMessage name="expiration_date" component={FormikErrorText} color={colors.main} width="250px" />
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", gap: "26px" }}>
                    <Typography sx={{ display: "inline", fontSize: "15px", fontWeight: "600", color: "black" }}>{t("expire_d")}</Typography>{" "}
                    <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>
                      {values.expiration_date && calculateDaysDiff(values.expiration_date)} {t("days")}
                    </Typography>{" "}
                  </Box>
                  <Box textAlign="center" display="flex" flexDirection="column" gap="10px">
                    <Typography sx={{ fontSize: "15px", fontWeight: "600", color: "black" }}>{t("forms.labels.localisation")}</Typography>{" "}
                    <Box>
                      <Field name="location" type="input" placeholder={t("forms.labels.localisation")} as={TextField} />
                      <ErrorMessage name="location" component={FormikErrorText} color={colors.main} width="250px" />
                    </Box>
                  </Box>
                  <Box textAlign="center" display="flex" gap="10px" flexDirection={"column"}>
                    <Box>
                      <Typography sx={{ fontSize: "15px", fontWeight: "600", color: "black" }}> {t("forms.labels.category")}</Typography>
                    </Box>
                    <Box>
                      <Autocomplete
                        sx={{ minWidth: "262px", maxWidth: "263px" }}
                        options={categories}
                        disableClearable
                        value={values.category}
                        getOptionLabel={(e) => e?.name}
                        isOptionEqualToValue={(option, value) => option?.value === value?.value}
                        onChange={(event, value) => {
                          if (value !== null) {
                            setFieldValue("category_value", value?.value);
                            setFieldValue("category", value);
                          } else {
                            setFieldValue("category", null);
                            setFieldValue("category_value", "");
                          }
                        }}
                        renderInput={(params) => <TextField fullWidth {...params} name="autocomplete" placeholder={t("forms.labels.category")} />}
                      />
                    </Box>
                    <ErrorMessage name="category_value" component={FormikErrorText} color={colors.main} width="250px" />
                  </Box>
                  <FormControlLabel
                    sx={{
                      fontWeight: "600",
                      color: colors?.main,
                      fontSize: "18px",
                    }}
                    control={
                      <Checkbox
                        checked={empty}
                        value={empty}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setDateUn(false);
                            setFieldValue("expiration_date", null);
                          }
                          setNoDispo(e.target.checked);
                          setEmpty(e.target.checked);
                        }}
                      />
                    }
                    label={t("forms.labels.tablette_vide")}
                  />
                  <FormControlLabel
                    sx={{
                      fontWeight: "600",
                      color: colors?.main,
                      fontSize: "18px",
                    }}
                    control={
                      <Checkbox
                        checked={date_unavaillable}
                        value={date_unavaillable}
                        onChange={(e) => {
                          setNoDispo(e.target.checked);
                          setDateUn(e.target.checked);
                          if (e.target.checked) {
                            setFieldValue("expiration_date", null);
                            setEmpty(false);
                          }
                        }}
                      />
                    }
                    label={t("forms.labels.date_non_disponible")}
                  />
                  <Box display="flex" justifyContent="center" gap="20px">
                    <Button disabled={isSubmitingChangeDate} type="submit" variant="primary">
                      {t("forms.submit.save")}
                    </Button>
                    <Button onClick={() => setOpenGestionDate(false)} variant="primary">
                      {t("forms.submit.close")}
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
      <Modal open={openGestionInvendu} onClose={() => setOpenGestionInvendu(false)}>
        <Box sx={modalStyle}>
          <Box sx={{ margin: "40px 0px" }}>
            <Box position="absolute" top="30px" right="10px">
              <IconButton onClick={() => setOpenGestionInvendu(false)}>
                <CloseOutlined />
              </IconButton>
            </Box>
            <Formik
              initialValues={initialValuesGestionInvendu}
              validationSchema={noDispo ? validationSchemaNoDate : validationSchemaInvendu}
              enableReinitialize
              onSubmit={(values) => {
                if (noDispo) InvenduWithoutDate(values);
                else InvenduWithDate(values);
              }}
            >
              {({ errors, values, setFieldValue }) => (
                <Form>
                  <Box display="flex" flexDirection="column" gap="20px">
                    <Box textAlign="center" display="flex" gap="10px" flexWrap={"wrap"}>
                      <Box sx={{ flex: "1", display: "flex", alignItems: "center" }}>
                        <Typography sx={{ fontSize: "15px", fontWeight: "600", color: "black" }}>{t("forms.labels.quantity")}</Typography>{" "}
                      </Box>
                      <Box sx={{ flex: "3" }}>
                        <Field name="quantity" type="number" placeholder={t("forms.labels.quantity")} as={TextField} />
                        <ErrorMessage name="quantity" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    </Box>{" "}
                    <Box textAlign="center" display="flex" gap="10px" flexWrap={"wrap"}>
                      <Box sx={{ flex: "1", display: "flex", alignItems: "center" }}>
                        <Typography sx={{ fontSize: "15px", fontWeight: "600", color: "black" }}>{t("forms.labels.action")}</Typography>{" "}
                      </Box>
                      <Box sx={{ flex: "2" }}>
                        <Autocomplete
                          options={action_types_list?.filter((action) => action?.des !== "CD" && action.des !== "RE")}
                          value={values.action}
                          getOptionLabel={(e) => e?.name}
                          isOptionEqualToValue={(option, value) => option?.des === value?.des}
                          onChange={(event, value) => {
                            if (value !== null) {
                              setFieldValue("action", value);
                            } else setFieldValue("action", { name: "", des: "" });
                          }}
                          renderInput={(params) => <TextField {...params} name="autocomplete" placeholder={action_types_list[0].name} />}
                        />
                        <ErrorMessage name="action.des" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    </Box>
                    <Box textAlign="center" display="flex" flexDirection={"column"} alignItems={"center"} gap="10px" flexWrap={"wrap"}>
                      <Box sx={{ flex: "1", display: "flex", alignItems: "center" }}>
                        <Typography sx={{ fontSize: "15px", fontWeight: "600", color: "black" }}>{t("forms.labels.expiration_date")}</Typography>{" "}
                      </Box>
                      <Box sx={{ flex: "3" }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <IconButton onClick={() => setFieldValue("expiration_date", null)}>
                            <Delete sx={{ color: colors?.main }} />
                          </IconButton>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                              inputFormat="yyyy-MM-dd"
                              value={values.expiration_date}
                              disabled={noDispo}
                              minDate={new Date()}
                              InputProps={{ sx: { "& .MuiSvgIcon-root": { color: colors?.main, height: "50px", width: "50px" } } }}
                              onChange={(value) => {
                                setFieldValue("expiration_date", moment(value).format("YYYY-MM-DD"));
                              }}
                              renderInput={(params) => <TextField sx={{ maxWidth: "250px" }} {...params} />}
                            />
                          </LocalizationProvider>

                          <IconButton onClick={() => setFieldValue("expiration_date", null)}>
                            <Delete sx={{ color: colors?.main }} />
                          </IconButton>
                        </Box>
                        <ErrorMessage name="expiration_date" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", gap: "26px" }}>
                      <Typography sx={{ display: "inline", fontSize: "15px", fontWeight: "600", color: "black" }}>{t("expire_d")}</Typography>{" "}
                      <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>
                        {values.expiration_date && calculateDaysDiff(values.expiration_date)} {t("days")}
                      </Typography>{" "}
                    </Box>
                    <Box textAlign="center" display="flex" gap="10px">
                      <Box sx={{ flex: "1", display: "flex", alignItems: "center" }}>
                        <Typography sx={{ fontSize: "15px", fontWeight: "600", color: "black" }}>{t("forms.labels.prix")}</Typography>{" "}
                      </Box>
                      <Box sx={{ flex: "3" }}>
                        <Field name="prix" type="number" placeholder={t("forms.labels.prix")} as={TextField} />
                        <ErrorMessage name="prix" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    </Box>{" "}
                    <Box textAlign="center" display="flex" gap="10px">
                      <Box sx={{ flex: "1", display: "flex", alignItems: "center" }}>
                        <Typography sx={{ fontSize: "15px", fontWeight: "600", color: "black" }}>{t("forms.labels.prix_red")}</Typography>{" "}
                      </Box>
                      <Box sx={{ flex: "3" }}>
                        <Field name="prix_red" type="number" placeholder={t("forms.labels.prix_red")} as={TextField} />
                        <ErrorMessage name="prix_red" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    </Box>
                    <Box textAlign="center" display="flex" gap="10px">
                      <Box sx={{ flex: "1", display: "flex", alignItems: "center" }}>
                        <Typography sx={{ fontSize: "15px", fontWeight: "600", color: "black" }}>{t("forms.labels.localisation")}</Typography>{" "}
                      </Box>
                      <Box sx={{ flex: "3" }}>
                        <Field name="location" type="input" placeholder={t("forms.labels.localisation")} as={TextField} />
                        <ErrorMessage name="location" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    </Box>
                    <Box textAlign="center" display="flex" gap="10px" alignItems={"center"}>
                      <Box>
                        <Typography sx={{ fontSize: "15px", fontWeight: "600", color: "black" }}> {t("forms.labels.category")}</Typography>
                      </Box>
                      <Box>
                        <Autocomplete
                          sx={{ minWidth: "200px", maxWidth: "200px" }}
                          options={categories}
                          disableClearable
                          value={values.category}
                          getOptionLabel={(e) => e?.name}
                          isOptionEqualToValue={(option, value) => option?.value === value?.value}
                          onChange={(event, value) => {
                            if (value !== null) {
                              setFieldValue("category_value", value?.value);
                              setFieldValue("category", value);
                            } else {
                              setFieldValue("category", null);
                              setFieldValue("category_value", "");
                            }
                          }}
                          renderInput={(params) => <TextField fullWidth {...params} name="autocomplete" placeholder={t("forms.labels.category")} />}
                        />
                      </Box>
                      <ErrorMessage name="category_value" component={FormikErrorText} color={colors.main} width="250px" />
                    </Box>
                    <FormControlLabel
                      sx={{
                        fontWeight: "600",
                        color: colors?.main,
                        fontSize: "18px",
                      }}
                      control={
                        <Checkbox
                          checked={empty}
                          value={empty}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setDateUn(false);
                              setFieldValue("expiration_date", null);
                            }
                            setNoDispo(e.target.checked);
                            setEmpty(e.target.checked);
                          }}
                        />
                      }
                      label={t("forms.labels.tablette_vide")}
                    />
                    <FormControlLabel
                      sx={{
                        fontWeight: "600",
                        color: colors?.main,
                        fontSize: "18px",
                      }}
                      control={
                        <Checkbox
                          checked={date_unavaillable}
                          value={date_unavaillable}
                          onChange={(e) => {
                            setNoDispo(e.target.checked);
                            setDateUn(e.target.checked);
                            if (e.target.checked) {
                              setFieldValue("expiration_date", null);
                              setEmpty(false);
                            }
                          }}
                        />
                      }
                      label={t("forms.labels.date_non_disponible")}
                    />
                    <Box display="flex" justifyContent="center" gap="20px">
                      <Button disabled={isSubmitingChangeDate} type="submit" variant="primary">
                        {t("forms.submit.save")}
                      </Button>
                      <Button onClick={() => setOpenGestionInvendu(false)} variant="primary">
                        {t("forms.submit.close")}
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
export default ProductDetailsEMP;
