import { Alert, Autocomplete, Box, Button, Checkbox, Collapse, FormControlLabel, IconButton, TextField, Typography } from "@mui/material";
import { useAuthContext } from "../../../hooks/useAuthContext";
import React, { useEffect, useState } from "react";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { useLogin } from "../../../hooks/useLogin";
import { toast, ToastContainer } from "react-toastify";
import imagePlaceholder from "../../../assets/placeholder-4-300x200.png";
import { useTranslation } from "react-i18next";
import { colors } from "../../../theme/colors";
import moment from "moment";
import debounce from "debounce";
import * as yup from "yup";
import Loading from "../../../components/ui/Loading";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";
import FormikErrorText from "../../../components/ui/FormikErrorText";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { categories_list_en, categories_list_es, categories_list_fr } from "../../../assets/data/categories";
const ListeProduitsEMPSearch = () => {
  const { user } = useAuthContext();
  const access_token = user?.access;
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const refresh_token = user?.refresh;
  const [noDispo, setNoDispo] = useState(false);
  const [date_unavaillable, setDateUn] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [produits, setProduits] = useState([]);
  const { refreshToken } = useLogin();
  const { t } = useTranslation();
  const lang = localStorage.getItem("i18nextLng");
  const [add, setAdd] = useState({ state: false, data: null });
  const [demandSent, setDemandSent] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [upc, setUpc] = useState(null);
  const [upcSearch, setUpcSearch] = useState(null);
  const { id } = useParams();
  const [modification, setModification] = useState(false);
  const [update, setUpdate] = useState(false);
  const [response, setResponse] = useState({});
  const categories = lang === "fr" ? categories_list_fr : lang === "en" ? categories_list_en : categories_list_es;

  //debounced search
  const debouncedSearch = React.useMemo(() => {
    return debounce((upc) => {
      setUpcSearch(upc);
    }, 700);
  }, []);
  useEffect(() => {
    debouncedSearch(upc);
  }, [upc]);
  const [initialValues, setinitialValues] = useState({
    expiration_date: null,
    location: "",
    format: "",
    upc: "",
    empty_shelf: false,
    date_non_dispo: false,
    category_value: categories[0]?.value,
    designation: "",
    category: categories[0],
  });
  const validationSchema = yup.object({
    upc: yup.string().required(t("validation_required")),
  });
  useEffect(() => {
    setLoading(true);
    Api.get(Urls.SearchProductEmployee(id), {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        offset: 0,
        limit: 25,
      },
    })
      .then((res) => {
        setProduits(res?.data?.results);
        setResponse(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            setModification(!modification);
          });
        } else {
          toast.error(err?.response?.data?.details);
          setLoading(false);
        }
      });
  }, [modification]);
  const handlePagination = (url) => {
    if (url) {
      Api.get(url, {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      })

        .then((res) => {
          setProduits((data) => {
            return res?.data?.results;
          });
          setResponse(res?.data);
        })
        .catch((err) => {
          if (err?.response?.data?.code === "token_not_valid") {
            refreshToken(refresh_token).then((res) => {
              console.log(res, "new user");
              setUpdate({ state: !update, url: url });
            });
          } else {
            toast.error(err?.response?.data?.details);
          }
          setLoading(false);
        });
    }
  };
  const calculateDaysDiff = (date2) => {
    const date1obj = new Date();
    const date2obj = new Date(date2);
    const timeDiff = date2obj - date1obj;
    const dayDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    return dayDiff;
  };
  useEffect(() => {
    if (add.data) {
      addProduct(add.data);
    }
  }, [add]);
  const addProduct = (values) => {
    Api.post(
      Urls.ADD_PRODUCT_EMPLOYEE,
      {
        upc: values.upc,
        designation: values.designation,
        format: values?.format,
        location: values.location,
        category: values.category_value,
        ...(values.expiration_date && { date: values.expiration_date }),
        empty_shelf: empty,
        expiration_date_not_available: date_unavaillable,
      },
      {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        setDemandSent(true);
        setOpenAddProduct(false);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            setAdd({ state: !add.state, data: values });
          });
        } else {
          toast.error(err?.response?.data?.details);
        }
      });
  };
  const Pagination = () => {
    return (
      <Box display="flex" alignItems="center" justifyContent="end">
        {response?.previous && (
          <IconButton
            onClick={() => {
              handlePagination(response?.previous);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            <ArrowLeft sx={{ fontSize: "40px" }} />
          </IconButton>
        )}
        <Box>
          <Typography variant="text_md_black">{response?.count}</Typography>
        </Box>
        {response?.next && (
          <IconButton
            onClick={() => {
              handlePagination(response?.next);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            <ArrowRight sx={{ fontSize: "40px" }} />
          </IconButton>
        )}
      </Box>
    );
  };
  useEffect(() => {
    handlePagination(update?.url);
  }, [update]);
  return (
    <Box>
      <ToastContainer />
      {loading && <Loading />}
      {!loading && (
        <Box display="flex" flexDirection="column" gap="24px">
          {produits?.map((prod) => (
            <Box
              onClick={() => {
                navigate("/employee/gestion-des-produits/liste/" + prod?.upc);
              }}
              sx={{ cursor: "pointer" }}
              display="flex"
              gap="20px"
              alignItems="center"
              backgroundColor="rgba(211,211,211 , 0.5)"
              borderRadius="16px"
              padding="10px"
            >
              <Box sx={{ flex: "1" }}>
                <img style={{ height: "auto", width: "auto", aspectRatio: "1/1" }} src={prod?.use_admin_image ? (prod.product_image?.product_image ? process.env.REACT_APP_IMAGE_URL + prod.product_image?.product_image : imagePlaceholder) : prod.private_product_image ? process.env.REACT_APP_IMAGE_URL + prod.private_product_image : imagePlaceholder} alt="produit" />
              </Box>
              <Box flex={1} sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>
                  UPC: <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.upc}</Typography>
                </Typography>
                <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>
                  <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.designation}</Typography>
                </Typography>
                <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>
                  {t("forms.labels.format")}: <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.format}</Typography>
                </Typography>
                <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>
                  {t("forms.labels.localisation")}: <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.location ? prod?.location : "-"}</Typography>
                </Typography>
                <Box>
                  <Typography sx={{ fontSize: "16px", fontWeight: "600", color: "black" }}>{t("forms.labels.expiration_date")}:</Typography>
                  <Typography sx={{ fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.expiration_date ? moment(prod?.expiration_date).format("MMMM, DD yyyy") : "-"}</Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "16px", fontWeight: "600", color: "black" }}>{t("expire_d")}:</Typography>
                  <Typography sx={{ fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.expiration_date ? calculateDaysDiff(prod?.expiration_date) + " " + t("days") : "-"}</Typography>
                </Box>
              </Box>
            </Box>
          ))}
          {produits?.length === 0 && !demandSent && (
            <Collapse in={!openAddProduct}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                <Typography textAlign={"center"} fontSize={"24px"} fontWeight="700">
                  {t("no_product_search")}
                </Typography>
                <Typography onClick={() => setOpenAddProduct(true)} sx={{ textAlign: "center", textDecoration: "underline", cursor: "pointer", fontSize: "16px", fontWeight: "600", color: colors.main }}>
                  {t("add_product")}
                </Typography>
              </Box>
            </Collapse>
          )}
          <Collapse in={openAddProduct} sx={{ padding: "0px 24px" }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize
              onSubmit={(values) => {
                addProduct(values);
              }}
            >
              {({ errors, values, setFieldValue }) => (
                <Form>
                  <Box display="flex" flexDirection="column" gap="20px">
                    <Box textAlign="center" display="flex" gap="10px">
                      <Box sx={{ flex: "1", display: "flex", alignItems: "center" }}>
                        <Typography sx={{ fontSize: "15px", fontWeight: "600", color: "black" }}>{t("forms.labels.designation")}</Typography>{" "}
                      </Box>
                      <Box sx={{ flex: "3" }}>
                        <Field name="designation" type="input" placeholder={t("forms.labels.designation")} as={TextField} />
                        <ErrorMessage name="designation" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    </Box>{" "}
                    <Box textAlign="center" display="flex" gap="10px">
                      <Box sx={{ flex: "1", display: "flex", alignItems: "center" }}>
                        <Typography sx={{ fontSize: "15px", fontWeight: "600", color: "black" }}>{t("forms.labels.format")}</Typography>{" "}
                      </Box>
                      <Box sx={{ flex: "3" }}>
                        <Field name="format" type="input" placeholder={t("forms.labels.format")} as={TextField} />
                        <ErrorMessage name="format" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    </Box>{" "}
                    <Box textAlign="center" display="flex" gap="10px">
                      <Box sx={{ flex: "1", display: "flex", alignItems: "center" }}>
                        <Typography sx={{ fontSize: "15px", fontWeight: "600", color: "black" }}>{t("forms.labels.localisation")}</Typography>{" "}
                      </Box>
                      <Box sx={{ flex: "3" }}>
                        <Field name="location" type="input" placeholder={t("forms.labels.localisation")} as={TextField} />
                        <ErrorMessage name="location" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    </Box>
                    <Box textAlign="center" display="flex" gap="10px">
                      <Box sx={{ flex: "1", display: "flex", alignItems: "center" }}>
                        <Typography sx={{ fontSize: "15px", fontWeight: "600", color: "black" }}>{t("forms.labels.expiration_date")}</Typography>{" "}
                      </Box>
                      <Box sx={{ flex: "3" }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            inputFormat="yyyy-MM-dd"
                            value={values.expiration_date}
                            disabled={noDispo}
                            minDate={new Date()}
                            InputProps={{ sx: { "& .MuiSvgIcon-root": { color: colors?.main, height: "30px", width: "30px" } } }}
                            onChange={(value) => {
                              setFieldValue("expiration_date", moment(value).format("YYYY-MM-DD"));
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                        <ErrorMessage name="expiration_date" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    </Box>
                    <Box textAlign="center" display="flex" gap="10px" alignItems={"center"}>
                      <Box>
                        <Typography sx={{ fontSize: "15px", fontWeight: "600", color: "black" }}> {t("forms.labels.category")}</Typography>
                      </Box>
                      <Box>
                        <Autocomplete
                          sx={{ minWidth: "220px", maxWidth: "263px" }}
                          options={categories}
                          disableClearable
                          value={values.category}
                          getOptionLabel={(e) => e?.name}
                          isOptionEqualToValue={(option, value) => option?.value === value?.value}
                          onChange={(event, value) => {
                            if (value !== null) {
                              setFieldValue("category_value", value?.value);
                              setFieldValue("category", value);
                            } else {
                              setFieldValue("category", null);
                              setFieldValue("category_value", "");
                            }
                          }}
                          renderInput={(params) => <TextField fullWidth {...params} name="autocomplete" placeholder={t("forms.labels.category")} />}
                        />
                      </Box>
                      <ErrorMessage name="category_value" component={FormikErrorText} color={colors.main} width="250px" />
                    </Box>
                    <FormControlLabel
                      sx={{
                        fontWeight: "600",
                        color: colors?.main,
                        fontSize: "18px",
                      }}
                      control={
                        <Checkbox
                          checked={empty}
                          value={empty}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setDateUn(false);
                              setFieldValue("expiration_date", null);
                            }
                            setNoDispo(e.target.checked);
                            setEmpty(e.target.checked);
                          }}
                        />
                      }
                      label={t("forms.labels.tablette_vide")}
                    />
                    <FormControlLabel
                      sx={{
                        fontWeight: "600",
                        color: colors?.main,
                        fontSize: "18px",
                      }}
                      control={
                        <Checkbox
                          checked={date_unavaillable}
                          value={date_unavaillable}
                          onChange={(e) => {
                            setNoDispo(e.target.checked);
                            setDateUn(e.target.checked);
                            if (e.target.checked) {
                              setFieldValue("expiration_date", null);
                              setEmpty(false);
                            }
                          }}
                        />
                      }
                      label={t("forms.labels.date_non_disponible")}
                    />
                    <Box textAlign="center" display="flex" gap="10px">
                      <Box sx={{ flex: "1", display: "flex", alignItems: "center" }}>
                        <Typography sx={{ fontSize: "15px", fontWeight: "600", color: "black" }}>UPC</Typography>{" "}
                      </Box>
                      <Box sx={{ flex: "3" }}>
                        <Field name="upc" type="number" placeholder={"UPC"} as={TextField} />
                        <ErrorMessage name="upc" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    </Box>{" "}
                    <Box display="flex" justifyContent="center" gap="20px">
                      <Button type="submit" variant="primary">
                        {t("forms.submit.save")}
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Collapse>
          <Collapse in={demandSent}>
            <Alert severity="success">{t("added")}</Alert>
          </Collapse>
          <Box marginBottom="24px">
            <Pagination />
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default ListeProduitsEMPSearch;
